import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { Message } from "theme-ui"
import CookieConsentBanner from "../cookie-consent/cookie-consent"
import Header from "../header/header"
import Footer from "../footer/footer"
import Navigation from "../navigation/navigation"

const shortcodes = { Message }

const Layout = ({ includeNav, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title || `Title`} />
      {includeNav ? <Navigation /> : <div />}
      <main>
        <MDXProvider components={shortcodes}>{children}</MDXProvider>
      </main>
      <Footer siteTitle={data.site.siteMetadata.title || `Title`} />
      <CookieConsentBanner />
    </>
  )
}

Layout.propTypes = {
  includeNav: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout

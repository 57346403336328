/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { Flex, jsx } from "theme-ui"

const FlexRow = ({
  paddingYTOverride,
  paddingYBOverride,
  bkColor,
  anchorID,
  reverseMobileOrder,
  children,
  ...props
}) => {
  return (
    <div
      sx={{
        mx: "auto",
        backgroundColor: `${bkColor}`,
      }}
      id={anchorID}
    >
      <Flex
        sx={{
          flexWrap: reverseMobileOrder
            ? ["wrap-reverse", null, "wrap"]
            : "wrap",
          maxWidth: "containerWidth",
          // maxWidth: [
          //   "100%",
          //   (theme) => `calc(${theme.sizes.containerWidth} / 2)`,
          // ],
          mx: "auto",
          py: "2.5rem",
          pt: paddingYTOverride ? 0 : null,
          pb: paddingYBOverride ? 0 : null,
        }}
        {...props}
      >
        {children}
      </Flex>
    </div>
  )
}

FlexRow.propTypes = {
  children: PropTypes.node.isRequired,
  paddingYTOverride: PropTypes.bool,
  paddingYBOverride: PropTypes.bool,
  bkColor: PropTypes.string,
  anchorID: PropTypes.string,
  reverseMobileOrder: PropTypes.bool,
}

FlexRow.defaultProps = {
  paddingYTOverride: false,
  paddingYBOverride: false,
  bkColor: null,
  anchorID: null,
  reverseMobileOrder: false,
}

export default FlexRow

/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import CookieConsent from "react-cookie-consent"
import { jsx } from "theme-ui"
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
// import { trackGoogleAnalytics } from "gatsby-plugin-gdpr-tracking"

const CookieConsentBanner = () => {
  // eslint-disable-next-line no-unused-vars
  const location = useLocation()
  return (
    <div
      sx={{
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        zIndex: "999",
        bg: "goreWhite",
        boxShadow: "0px -1px 8px 1px rgba(0, 0, 0, 0.5)",
        ".cc-container": {
          alignItems: "center",
          color: "goreBlack",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          maxWidth: "containerWidth",
          px: "defaultPadding",
          mx: "auto",
          pb: ["1rem", "2rem"],
          "> div": {
            pt: ["1rem", "2rem"],
          },
        },
        ".cc-content": {
          flex: ["1 0 100%", "1 0 40%"],
          fontFamily: "body",
          fontSize: "1rem",
          color: "goreBlack",
          lineHeight: 1.5,
        },
        ".cc-button-wrap": {
          flex: "0 1 auto",
        },
        ".cc-button, .cc-button-no": {
          fontFamily: "body",
          fontWeight: "bold",
          fontSize: "1rem",
          color: "goreWhite",
          textTransform: "uppercase",
          bg: "goreDarkSteel",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "goreDarkSteel",
          borderRadius: 0,
          pb: "0.5625rem",
          pt: "0.625rem",
          px: "1.5rem",
          // ml: [0, "2rem"],
          ml: [0, "3.5rem"],
          mr: ["1rem", 0],
          "&:hover": {
            color: "goreWhite",
            bg: "goreBlack",
            borderColor: "goreBlack",
            cursor: "pointer",
          },
        },
        ".cc-button-no": {
          color: "goreDarkSteel",
          bg: "transparent",
          borderColor: "goreDarkSteel",
        },
      }}
    >
      <CookieConsent
        // debug
        // enableDeclineButton
        disableStyles
        location="bottom"
        buttonClasses="cc-button"
        buttonWrapperClasses="cc-button-wrap"
        declineButtonClasses="cc-button-no"
        containerClasses="cc-container"
        contentClasses="cc-content"
        buttonText="Accept"
        ariaAcceptLabel="Accept"
        declineButtonText="Reject"
        ariaDeclineLabel="Reject"
        cookieName="gil-gdpr-google-analytics"
        onAccept={() => {
          initializeAndTrack(location)
        }}
        sameSite="none"
        cookieSecurity
      >
        We use cookies to enhance site navigation, analyze site usage, and
        support our marketing efforts. By accepting cookies, you are allowing
        them to be stored on your device.
      </CookieConsent>
    </div>
  )
}

export default CookieConsentBanner

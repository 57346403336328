/* eslint-disable react/no-danger */
import React from "react"
import PropTypes from "prop-types"
import showdown from "showdown"

const converter = new showdown.Converter()

const MarkdownContent = ({ content, className }) => (
  <span
    // css={`
    //   img + img {
    //     display: inline-block;
    //   }
    //   .inline-img {
    //     display: inline-block;
    //     padding-right: 4rem;
    //   }
    // `}
    className={className}
    dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
  />
)

MarkdownContent.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
}

MarkdownContent.defaultProps = {
  content: null,
  className: null,
}

export default MarkdownContent

/** @jsx jsx */
import { Link } from "gatsby"
import { Styled, jsx } from "theme-ui"
import FlexColumn from "../flex-column/flex-column"
import FlexRow from "../flex-row/flex-row"

const Footer = () => {
  return (
    <footer
      sx={{
        mx: "auto",
        backgroundColor: "goreFooterGray",
      }}
    >
      <FlexRow>
        <FlexColumn cellCount="2">
          <Styled.p>
            <Link
              to="https://www.gore.com/privacy"
              sx={{
                color: "goreRed",
                textDecoration: "none",
                mr: "1rem",
              }}
              target="_blank"
            >
              Privacy Notice
            </Link>
            <Link
              to="https://www.gore.com/terms-of-use"
              sx={{
                color: "goreRed",
                textDecoration: "none",
              }}
            >
              Terms of Use
            </Link>
          </Styled.p>
          <Styled.p>
            <Link
              to="/"
              sx={{
                color: "goreBlack",
                textDecoration: "none",
              }}
              target="_blank"
            >
              &copy; {new Date().getFullYear()} W. L. Gore & Associates, Inc.
            </Link>
          </Styled.p>
        </FlexColumn>
        <FlexColumn cellCount="2">
          <Styled.p>
            <Link
              to="https://www.gore.com/modern-slavery-act-transparency-statement"
              sx={{
                color: "goreRed",
                textDecoration: "none",
              }}
              target="_blank"
            >
              California Supply Chain Act / Human Trafficking Statement
            </Link>
          </Styled.p>
        </FlexColumn>
      </FlexRow>
    </footer>
  )
}

export default Footer

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react"
import PropTypes from "prop-types"
import { Flex, Box, Button, Styled, jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Link from "../link/link"

const activeNavStyle = {
  "&.isCurrent": {
    color: "goreBlack",
  },
}

const hoverStyle = {
  "&:hover": {
    color: "goreBlack",
  },
}

const Navigation = ({ data }) => {
  const [navIsOpen, setNavIsOpen] = useState(false)
  return (
    <nav>
      <Flex
        sx={{
          margin: "0 auto",
          maxWidth: "containerWidth",
          px: "defaultPadding",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Styled.h2
          sx={{
            flex: "0 0 auto",
            fontSize: ["1.125rem", null, "1.25rem"],
            my: ["1.25rem", null, "1.5rem"],
          }}
        >
          Gore Innovation Labs
        </Styled.h2>
        <Box
          className={navIsOpen ? "nav-open" : "nav-closed"}
          sx={{
            flex: "1 0 auto",
            textAlign: "right",
          }}
        >
          <Button
            variant="hamburger"
            aria-label="Main Navigation"
            role="navigation"
            onClick={() => setNavIsOpen(!navIsOpen)}
            onKeyPress={() => setNavIsOpen(!navIsOpen)}
            sx={{
              display: [null, null, "none"],
            }}
          >
            &#9776;
          </Button>
        </Box>
        <ul
          className={navIsOpen ? "nav-open" : "nav-closed"}
          sx={{
            flex: ["1 1 100%", null, "1 0 auto"],
            textAlign: ["left", null, "right"],
            pl: 0,
            my: 0,
            pb: ["0.375rem", null, 0],
            li: {
              display: [null, null, "inline"],
              ml: [0, null, "2rem"],
              listStyle: "none",
            },
            "&.nav-closed": {
              display: ["none", null, "block"],
            },
          }}
        >
          {data.navMenuItems.map(({ linkLoc, linkName }) => (
            <li
              sx={{
                py: ["0.75rem", null, 0],
                borderTopWidth: ["1px", null, 0],
                borderTopStyle: "solid",
                borderTopColor: "goreLightGray",
              }}
            >
              <Link
                to={linkLoc}
                activeClassName="isCurrent"
                partiallyActive="isCurrent"
                sx={{
                  fontWeight: "bold",
                  textDecoration: "none",
                  color: "goreDarkGray",
                  ...hoverStyle,
                  ...activeNavStyle,
                }}
              >
                {linkName}
              </Link>
            </li>
          ))}
        </ul>
      </Flex>
    </nav>
  )
}

Navigation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  // menuName: PropTypes.string,
}

Navigation.defaultProps = {
  // menuName: "primary",
}

// const NavigationWithQuery = (props, menuName) => {
const NavigationWithQuery = (props) => {
  const { dataYaml } = useStaticQuery(
    graphql`
      query {
        dataYaml(navMenuItems: {}, navMenu: { eq: "primary" }) {
          navMenu
          navMenuItems {
            linkLoc
            linkName
          }
        }
      }
    `
  )
  return (
    <Navigation
      data={dataYaml}
      // menuName={menuName}
      {...props}
    />
  )
}

export default NavigationWithQuery

/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { Box, jsx } from "theme-ui"

const setColumnSpacing = (cellCount, reverseMobileOrder) => {
  return {
    px:
      cellCount >= 3
        ? ["defaultPadding", "columnSpacingNarrow"]
        : ["defaultPadding", "columnSpacing"],
    pb: ["1rem", 0],
    "&:first-child": {
      pl: "defaultPadding",
      pb: reverseMobileOrder ? 0 : null,
    },
    "&:last-child": {
      pr: "defaultPadding",
      // pb: 0,
      pb: reverseMobileOrder ? null : 0,
    },
  }
}

const setColumnWidth = (cellCount, cellWidth) => {
  return {
    width:
      cellWidth !== null
        ? ["100%", `calc(100% / ${cellWidth})`]
        : ["100%", `calc(100% / ${cellCount})`],
    // cellWidth !== null
    //   ? ["100%", `calc(100% / ${cellWidth})`]
    //   : [
    //       "100%",
    //       (theme) => `calc(${theme.sizes.containerWidth} / ${cellCount} )`,
    //     ],
  }
}

const setColumnHeight = (marginOverride, blockCellType) => {
  let mtOverride = false
  if (blockCellType === "Form Column") {
    mtOverride = true
  }
  return {
    "span > *:first-child": {
      mt: mtOverride ? [null, 0] : 0,
    },
    "span > *:last-child": {
      mb: marginOverride ? null : 0,
    },
  }
}

const FlexColumn = ({
  blockCellType,
  cellCount,
  cellWidth,
  marginOverride,
  reverseMobileOrder,
  children,
  ...props
}) => {
  return (
    <Box
      sx={{
        ...setColumnWidth(cellCount, cellWidth),
        flex: "0 0 auto",
        ...setColumnSpacing(cellCount, reverseMobileOrder),
        ...setColumnHeight(marginOverride, blockCellType),
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

FlexColumn.propTypes = {
  blockCellType: PropTypes.string,
  cellCount: PropTypes.number,
  cellWidth: PropTypes.number,
  marginOverride: PropTypes.bool,
  reverseMobileOrder: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

FlexColumn.defaultProps = {
  blockCellType: "Normal Column",
  cellCount: 1,
  cellWidth: null,
  marginOverride: false,
  reverseMobileOrder: false,
}

export default FlexColumn

/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Flex, Image, Styled, jsx } from "theme-ui"
import PrimaryTexture from "../../assets/images/primary-texture.svg"
import Logo from "../../assets/images/gore-logo.svg"
import BrandPromise from "../../assets/images/gore-brand-promise.svg"

const Header = ({ siteTitle }) => {
  return (
    <header
      sx={{
        background: (theme) =>
          `linear-gradient(to right, ${theme.colors.goreRedTinted} 0%, ${theme.colors.goreRedTinted} 50%, ${theme.colors.goreRed} 50%, ${theme.colors.goreRed} 100%)`,
      }}
    >
      <Flex
        sx={{
          margin: "0 auto",
          maxWidth: "containerWidth",
          p: "defaultPadding",
          backgroundImage: `url(${PrimaryTexture})`,
          backgroundSize: "100%",
          backgroundPosition: "center 66%",
          backgroundRepeat: "no-repeat",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Styled.h1 sx={{ margin: 0 }}>
          <Link
            to="/"
            sx={{
              color: "goreWhite",
              textDecoration: "none",
            }}
          >
            <Image src={Logo} variant="logo" name={siteTitle} />
          </Link>
        </Styled.h1>
        <Link
          to="/"
          sx={{
            color: "goreWhite",
            textDecoration: "none",
          }}
        >
          <Image src={BrandPromise} variant="brandPromise" name={siteTitle} />
        </Link>
      </Flex>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
